import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SocketBookingsService {

  constructor(private socket: Socket) { }
  createBooking(booking : any) {
    return new Observable(observer => {
       this.socket.emit('createBooking', booking);
       observer.next(this.socket.ioSocket.id);
       console.log(this.socket)
    });
  }
  joinRoom(joinRoomData) {
    this.socket.emit('joinRoom', joinRoomData);
    console.log(joinRoomData)
  }
  getRoomUsers(){
    return new Observable(observer => {
      this.socket.on('roomUsers', (data) => {
        observer.next(data);
        console.log(data)
      });
    });
  }
  endBooking(booking : any) {
    this.socket.emit('endBooking', booking);
  }
  getBookingApprovalCount(userDetails) {
    this.socket.emit('bookingApprovalCount',userDetails);
  }
  getBookingApprovalNotification(){
    return new Observable(observer => {
      this.socket.on('getBookingApprovalCount', (data) => {
        observer.next(data);
        console.log(data)
      });
    });
  }
  onSocketError(){
    return new Observable(observer => {
      this.socket.on('connect_error', (error) => {
        console.log('error======',error)
        observer.next(error);
        
      });
    });
  }
  
  getBookings() {
    return new Observable(observer => {
      this.socket.on('bookingCreated', (data) => {
        observer.next(data);
        console.log('bookingCreated=======',data)
      });
      this.socket.on('bookingEnded', (data) => {
        observer.next(data);
        console.log('bookingEnded=====',data)
      });
      // return () => {
      //   this.socket.disconnect();
      // }
    });
    // return this.socket.on('bookingCreated',data => console.log(data) );
  }

 

  // getAllVisits(visits:any){
  //   return new Observable(observer => {
  //     this.socket.emit('getVisits', visits);
  //     observer.next(this.socket);
  //     console.log('visitvisit',visits);
  //     console.log('this.socket',this.socket);
  //     console.log('this.socket.ioSocket.id',this.socket.ioSocket.ids);
  //  });
  // }

  // getAllVisitsFromBackend(){
  //   return new Observable(observer => {
  //     this.socket.on('visitsData', (data) => {
  //       observer.next(data);
  //       console.log('visitsData',data)
  //     });
  //   });
  // }
  getVisitWithFilters(payload) {
    this.socket.emit('getVisits', payload);
    console.log('payload',payload)

  }
  visitsResponse(){
    return new Observable(observer => {
      this.socket.on('visitsData', (data) => {
        observer.next(data);
        console.log('visitsData',data)

      });
    });
  }

  visitStatusChanged(){
    return new Observable(observer => {
      this.socket.on('visitStatusChanged', (data) => {
        observer.next(data);
        console.log('visitData',data)
      });
    });
  }

  visitStatusChange(visit : any) {
    // this.socket.emit('visitStatusChange', visit);
    return new Observable(observer => {
      this.socket.emit('visitStatusChange', visit);
      observer.next(this.socket.ioSocket.id);
   });
  }

  visitCreated(){
    return new Observable(observer => {
      this.socket.on('visitCreated', (data) => {
        observer.next(data);
        console.log('visitCreated',data)
      });
    });
  }

  createVisit(createVisit){
    this.socket.emit('createVisit', createVisit);
    console.log('createVisit',createVisit);
  //   return new Observable(observer => {
  //     this.socket.emit('createVisit', createVisit);
  //     alert('createVisit')
  //     observer.next(this.socket.ioSocket.id);
  //     console.log('createVisit',createVisit);
  //  });
  }
}
