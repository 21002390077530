import { Injectable } from '@angular/core'


@Injectable({
    providedIn: 'root'
})
  export class Validation {
    validatePhoneNumber(phoneNumber, maxLength = 10): boolean{
        console.log('maxLength=====',maxLength)
        if(!phoneNumber){
            return false;
        }
        if(phoneNumber.length && phoneNumber.length !=maxLength){
            return false;
        }
        if(!Number(phoneNumber)){
            return false;
        }
        if(Number(phoneNumber).toString().length !=maxLength){
            return false;
        }
        return true
    }

  }