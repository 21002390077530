export const Constants = {
    apiPathSocket: 'http://api.diginav.in/',
    // apiPathSocket: 'http://10.10.1.35:5000/', //kunal localhost
    apiPath: 'http://api.diginav.in/',
    // apiPath: 'http://10.10.1.35:5000/', //kunal localhost
    razorpayKey: 'rzp_test_MmrjqXRXBo9Ag6',
    // apiPathLive: 'https://stage.qdesq.com/api',
    apiPathLive: 'https://api.qdesq.com',
    version : "1.1.5",
    development: window.location.origin.indexOf('stage')>=0 || window.location.origin.indexOf('test.')>=0 || window.location.origin.indexOf('localhost')>=0 || false
}
  