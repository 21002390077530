import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-product-qr-code',
  templateUrl: './product-qr-code.component.html',
  styleUrls: ['./product-qr-code.component.scss']
})
export class ProductQrCodeComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
  }
  navigate(x){
    if(x == 'meeting-room'){
      window.open( `employee-access-view?companyId=61fbfae271496414b293bdd0&officeName=New-Delhi&floor=F-1`, '_self');
    }else{
      window.open( `visitor-checkin?refId=627b89464562751b8cc14ecb`, '_self');
    }
  }
}
