import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { Constants } from './global-data/constants'
import {NgxPaginationModule} from 'ngx-pagination';
import { ProductQrCodeComponent } from './product-qr-code/product-qr-code.component';
import { CommonModule } from '@angular/common';
import { SharedModalComponent } from './shared-modal/shared-modal.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// import { SafePipe } from './@services/safe.pipe';
// import { LandingPageComponent } from './landing-page/landing-page.component';
const config: SocketIoConfig = {
	url: window.location.href.indexOf('https://diginav.in/')>=0 ? 'http://api.diginav.in/':'http://api.diginav.in/' , // socket server url;
	options: {
		transports: ['websocket']
	}
}

@NgModule({
  declarations: [
    AppComponent,
    ProductQrCodeComponent,
    SharedModalComponent,
    // SafePipe,
    // LandingPageComponent    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    FormsModule,
    SocketIoModule.forRoot(config), 
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
if (environment.production) {
  enableProdMode()
  if (window.location.href.indexOf(' https://diginav.in/') > -1) {
    console.log('© Powered by Qdesq Realtech Pvt. Ltd.')
    // window.console.log = function () {}
    Constants.apiPath = 'http://api.diginav.in/'
    Constants.apiPathLive = 'https://api.qdesq.com'
    Constants.razorpayKey = 'rzp_live_mni7cesLf1G9By'
  } else {
    console.log('meta')
    // $('head').append( '<meta name="robots" content="noindex, nofollow">' )
    // document.querySelector('meta[name="robots"]').setAttribute("content", 'noindex, nofollow');
  }
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    console.log('registration-----',registrations)
    if(registrations && registrations.length>0){
      for(let registration of registrations) {
        // console.log('registration-----',registration.unregister)
        registration.unregister();
      }
      setTimeout(() => {
        window.location.reload();
      },1000)
    }
  });
}