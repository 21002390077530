import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationClass } from './auth'
import jwt_decode from 'jwt-decode'
import * as moment from 'moment'
import { CompanyDetailsService } from '../@services/company-details.service'
import { Constants } from '../global-data/constants'
import { HttpClient } from '@angular/common/http'
import { ignoreLoginNavigationUrls } from '../global-data/commonData'
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  userData: any = {}
  private CurrentAuthSource = new BehaviorSubject<object>({
    isLoggedIn: localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val ? this.getCompanyDetails(() => { this.CurrentAuthSource.next({isLoggedIn: true}) }) : false,
  })
  
  getCurrentLoginStatus = this.CurrentAuthSource.asObservable()
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private storeCompanyDetails : CompanyDetailsService,
    private http: HttpClient,
    ) {}

  setImagekitKeys(imagekitKey: any) {
    localStorage.setItem('imagekitKey', JSON.stringify(imagekitKey))
    var result = { message: 'keys saved', imagekitKey: imagekitKey }
    return result
  }
  setVisitorId(visitorId: any) {
    localStorage.setItem('visitorId', JSON.stringify(visitorId))
    var result = {visitorId: visitorId}
    return result
  }
  getVisitorId() {
    let keys = JSON.parse(localStorage.getItem('visitorId'))
    return keys
  }
  getImagekitKeys() {
    let keys = JSON.parse(localStorage.getItem('imagekitKey'))
    return keys
  }
  clearAll() {
    localStorage.clear()
  }
  async SaveLoggedInUser(LoggedInUser, sessionName = 'auth.token') {
    var result: any = { success: true, message: 'you are now logged in' }
    localStorage.setItem(sessionName, this.auth(LoggedInUser.token))
    this.getCompanyDetails(() => {
      AuthenticationClass.IsValidLogin = true
      this.CurrentAuthSource.next({ isLoggedIn: AuthenticationClass.IsValidLogin })
      return result
    })
    
  }
  async Logout(ignoreLoginNavigation=false) {
    this.storeCompanyDetails.companyDetails = null
    this.storeCompanyDetails.featuresEnabled = []
    localStorage.clear()
    AuthenticationClass.IsValidLogin = false
    // this.authService.signOut()
    // window.location.reload()
    if(!ignoreLoginNavigation){
      this.CurrentAuthSource.next({ isLoggedIn: AuthenticationClass.IsValidLogin })
    }
    
    
    return true
  }
  auth(str) {
    let obj = { val: str, lifetime: -1, timestamp: new Date().getTime() }
    return JSON.stringify(obj)
  }
  getUser(ignoreLoginNavigation=false,sessionName = 'auth.token') {
    ignoreLoginNavigation = ignoreLoginNavigation || this.isIgnoreUrls() 
    if (localStorage.getItem(sessionName) && JSON.parse(localStorage.getItem(sessionName)).val) {
      this.userData = jwt_decode(localStorage.getItem(sessionName))
    } else {
      this.userData = {}
    }
    if (this.userData && this.userData.expiresOn) {
      if (this.userData.expiresOn < moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')) {
        this.Logout(ignoreLoginNavigation)
        if(!ignoreLoginNavigation){
          this.router.navigate(['/login'])
        }
      }
    }
    this.userData.userId = this.userData.userId ? this.userData.userId : this.userData.userid
    return {
      user_img: this.userData.picture ? this.userData.picture : null,
      name: this.userData.name ? this.userData.name : null,
      userId: this.userData.userId ? this.userData.userId : null,
      email: this.userData.email ? this.userData.email.trim().toLowerCase() : null,
      number: this.userData.contactNumber ? this.userData.contactNumber : null,
      isAdmin: this.userData.isAdmin ? this.userData.isAdmin : null,
      companyId : this.userData.companyId ? this.userData.companyId : null,
      officeId : this.userData.officeId ? this.userData.officeId : null,
      domainName: this.userData.domainName,
      roles: this.userData.role ? this.userData.role : [],
      countryCode:this.userData.countryCode,
      visitorId: this.userData.visitorId || null,
      userType: this.userData.userType
    }
  }
  getCompanyDetails(callBack){
    const options = {
      params: null,
      headers: {},
    }
    if(localStorage.getItem('auth.token') && JSON.parse(localStorage.getItem('auth.token')).val){
      options.headers = {
        Authorization: localStorage.getItem('auth.token')
          ? 'Bearer ' + JSON.parse(localStorage.getItem('auth.token')).val
          : null,
      }
    }
    if(!this.getUser().companyId){
      callBack();
      return;
    };
    this.http.get(Constants.apiPath + 'companies/'+this.getUser().companyId, options).subscribe(
        (response:any) => {
          this.storeCompanyDetails.companyDetails = response
          callBack()
          return response
        },
        (err) => {
          return err
        }
    )
  }
  setVisitorCheckinRefId(refId){
    localStorage.setItem('visitor.checkin.admin.login.RefID',refId)
  }
  getVisitorCheckinRefId() {
    if(localStorage.getItem('visitor.checkin.admin.login.RefID') && localStorage.getItem('visitor.checkin.admin.login.RefID').length>0){
      return localStorage.getItem('visitor.checkin.admin.login.RefID')
    }
    return null;
  }
  clearVisitorCheckinRefId(){
    localStorage.removeItem('visitor.checkin.admin.login.RefID')
  }
  setSSOLoginRedirectURI(url){
    localStorage.setItem('sso.login.redirect.uri',url);
  }
  getSSOLoginRedirectURI(){
    return localStorage.getItem('sso.login.redirect.uri');
  }
  removeSSOLoginRedirectURI(){
    localStorage.removeItem('sso.login.redirect.uri');
  }
  setPwaDetails(data, key){
    localStorage.setItem(key,JSON.stringify(data))
  }
  getPwaDetails(key){
    if(localStorage.getItem(key) && JSON.parse(JSON.stringify(localStorage.getItem(key)))){
      return JSON.parse(localStorage.getItem(key))
    }
    return null;
  }

  isIgnoreUrls(){
    for(let url of ignoreLoginNavigationUrls){
      if(window.location.href.indexOf(url)>=0){
        return true
      }
    }
    return false;
  }
}
