import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ProductQrCodeComponent } from './product-qr-code/product-qr-code.component';
const routes: Routes = [
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((mod) => mod.ContactUsModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then((mod) => mod.AboutUsModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./career-page/career-page.module').then((mod) => mod.CareerPageModule)
  },
  {
    path: 'reactivate-account',
    pathMatch:'full',
    loadChildren: () => import('./reactivate-account/reactivate-account.module').then((mod) => mod.ReactivateAccountModule)
  },
  {
    path: 'html-field-generator',
    pathMatch:'full',
    loadChildren: () => import('./common-modules/html-input-field-generator/html-input-field-generator.module').then((mod) => mod.HtmlInputFieldGeneratorModule)
  },
  {
    path: 'add-custom-fields-ui',
    pathMatch:'full',
    loadChildren: () => import('./common-modules/add-custom-fields-ui/add-custom-fields-ui.module').then((mod) => mod.AddCustomFieldsUIModule)
  },
  {
    path: 'feedback-and-complaints',
    // pathMatch:'full',
    loadChildren: () => import('./feedback-user-flow/feedback-user-flow.module').then((mod) => mod.FeedbackUserFlowModule)
  },
  {
    path: 'visitor-approval-requests/:companyId',
    loadChildren: () => import('./visitor-approval-requests/visitor-approval-requests.module').then((mod) => mod.VisitorApprovalRequestsModule)
  },
  // {
  //   path: 'company-auto-completer',
  //   pathMatch:'full',
  //   loadChildren: () => import('./company-auto-completer/company-auto-completer.module').then((mod) => mod.CompanyAutoCompleterModule)
  // },
  {
    path: 'thanksforcontacting',
    pathMatch:'full',
    loadChildren: () => import('./thank-you-page/thank-you-page.module').then((mod) => mod.ThankYouPageModule)
  },
  {
    path: 'calender',
    loadChildren: () => import('./common-modules/calender-module/calender-module.module').then((mod) => mod.CalenderModuleModule)
  },
  {
    path: 'booking-approval',
    loadChildren: () => import('./booking-approval/booking-approval.module').then((mod) => mod.BookingApprovalModule)
  },
  {
    path: 'security-team-pass-verification',
    loadChildren: () => import('./security-team/security-team.module').then((mod) => mod.SecurityTeamModule)
  },
  {
    path: 'visit-confirm',
    loadChildren: () => import('./visitor-confirmation/visitor-confirmation.module').then((mod) => mod.VisitorConfirmationModule)
  },
  {
   path: 'meeting-room-and-desk-booking',
   pathMatch: 'full',
   loadChildren: () => import('./meeting-room-and-desk-booking/meeting-room-and-desk-booking.module').then((mod) => mod.MeetingRoomAndDeskBookingModule)
 },  
 {
    path: 'visitor-management',
    pathMatch: 'full',
    loadChildren: () => import('./visitor-self-check-in/visitor-self-check-in.module').then((mod) => mod.VisitorSelfCheckInModule)
  },  
  {
    path: 'feedback-and-complaint-box',
    pathMatch: 'full',
    loadChildren: () => import('./feedback-complaint-product-page/feedback-complaint-product-page.module').then((mod) => mod.FeedbackComplaintProductPageModule)
  },  
  {
    path: 'demo-product',
    component: ProductQrCodeComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./login-signup-page/login-signup-page.module').then((mod) => mod.LoginSignupPageModule),
  },
  // {
  //   path: 'register-organisation',
  //   loadChildren: () => import('./organisation-register-form/organisation-register-form.module').then((mod) => mod.OrganisationRegisterFormModule),
  // },
  {
    path: 'super-user-login',
    loadChildren: ()=> import('./super-user/super-user.module').then((mod)=> mod.SuperUserModule)
  },
  {
    path: 'login-to-internal-crm',
    loadChildren: ()=> import('./internal-crm-login/internal-crm-login.module').then((mod)=> mod.InternalCrmLoginModule)
  },
  {
    path: 'visitor-checkin',
    loadChildren: () => import('./visitor-checkin/visitor-checkin.module').then((mod) => mod.VisitorCheckinModule),
  },
  {
    path: 'qdesq/visitor-checkin',
    loadChildren: () => import('./visitor-checkin/visitor-checkin.module').then((mod) => mod.VisitorCheckinModule),
  },
  {
    path: 'checkin',
    loadChildren: () => import('./view-pass/view-pass.module').then((mod) => mod.ViewPassModule),
  },
  {
    path: 'meeting-room',
    loadChildren: () => import('./common-modules/meeting-room-analytics/meeting-room-analytics.module').then((mod) => mod.MeetingRoomAnalyticsModule),
  },
  {
    path: 'visitor',
    loadChildren: () => import('./common-modules/visitor-room-analytics/visitor-room-analytics.module').then((mod) => mod.VisitorRoomAnalyticsModule),
  },
  {
    path: 'visitor-checkout',
    loadChildren: () => import('./visitor-checkout/visitor-checkout.module').then((mod) => mod.VisitorCheckoutModule),
  },
  {
    path: 'visitor-approval',
    loadChildren: () => import('./visitor-approval/visitor-approval.module').then((mod) => mod.VisitorApprovalModule),
  },
  {
    path: 'visitor-login',
    loadChildren: () => import('./visitor-login-model/visitor-login-model.module').then((mod) => mod.VisitorLoginModelModule),
  },
  {
    path: 'qdesq/visitor-approval',
    loadChildren: () => import('./visitor-approval/visitor-approval.module').then((mod) => mod.VisitorApprovalModule),
  },
  {
    path: 'visitor-prebooking',
    loadChildren: () => import('./visitor-pre-booking/visitor-pre-booking.module').then((mod) => mod.VisitorPreBookingModule),
  },
  {
    path: 'visitor-prebooking/:refId',
    loadChildren: () => import('./visitor-pre-booking/visitor-pre-booking.module').then((mod) => mod.VisitorPreBookingModule),
  }, 
  {
    path: 'visitor-documents',
    loadChildren: () => import('./visitor-document/visitor-document.module').then((mod) => mod.VisitorDocumentModule),
  },
  {
    path: 'onboard-building',
    loadChildren: () => import('./onboard-building/onboard-building.module').then((mod) => mod.OnboardBuildingModule),
  },
  {
    path : 'register-organisation', 
    loadChildren:()=> import('./company-onboarding/company-onboarding.module').then((mod => mod.CompanyOnboardingModule))
  },
  {
    path: 'offices-onboarding',
    loadChildren : () => import('./offices-onboarding/offices-onboarding.module').then((mod) => mod.OfficesOnboardingModule)
  },
  {
    path: 'admin-dash',
    loadChildren: () => import('./admin-dash/admin-dash.module').then((mod) => mod.AdminDashModule),
  },
  
  {
    path: 'employee-access-view',
    pathMatch: 'full',
    loadChildren: () => import('./employee-section/employee-section.module').then((mod) => mod.EmployeeSectionModule),
  },
  {
    path: 'generate-qr-code',
    pathMatch: 'full',
    loadChildren: () => import('./generate-qr-code/generate-qr-code.module').then((mod) => mod.GenerateQrCodeModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then((mod) => mod.TermsModule),
  },
  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then((mod) => mod.PolicyModule),
  },
  {
    path: 'self-checkIn',
    pathMatch: 'full',
    redirectTo:'visitor-management'
  },  
 {
    path: 'privacy-policy',
    redirectTo:'policy'
  },
  {
    path: 'organization/:type',
    pathMatch: 'full',
    loadChildren: () => import('./admin-section/admin-section.module').then((mod) => mod.AdminSectionModule),
  },
  {
    path: 'security-team-pass-verification',
    loadChildren: () => import('./security-team/security-team.module').then((mod) => mod.SecurityTeamModule)
  },
  {
    path: 'internal-crm',
    loadChildren: () => import('./internal-crm/internal-crm.module').then((mod) => mod.InternalCrmModule)
  },
  {
    path: 'visit/feedback',
    loadChildren: () => import('./visitor-feedback/visitor-feedback.module').then((mod) => mod.VisitorFeedbackModule)
  },
  {
    path: 'account-deactivated/:companyId',
    loadChildren: () => import('./company-deactivated/company-deactivated.module').then((mod) => mod.CompanyDeactivatedModule)
  },
 
  {
    path: 'view-images',
    loadChildren: () => import('./admin-dash/feedback-and-complaints-management/view-images/view-images.module').then((mod) => mod.ViewImagesModule)
  },
  {
    path:'visitor-checkin-admin-login',
    loadChildren: () => import('./visitor-checkin-admin-login-page-for-app/visitor-checkin-admin-login-page-for-app.module').then((mod) => mod.VisitorCheckinAdminLoginPageForAppModule)
    
  },
  {
    path: 'sso',
    loadChildren: () => import('./sso-logins/sso-logins.module').then((mod) => mod.SsoLoginsModule)
  },
  {
    path: 'check-pass-status/:visitId',
    loadChildren: () => import('./view-pass-status/view-pass-status.module').then((mod) => mod.ViewPassStatusModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./users/users.module').then((mod) => mod.UsersModule)
  },  
  {
    path: '',
    // pathMatch: 'full',
    // loadChildren: () => import('./landing-page/landing-page.module').then((mod) => mod.LandingPageModule)
    loadChildren: () => import('./feedback-user-flow/feedback-user-flow.module').then((mod) => mod.FeedbackUserFlowModule)
  },
  {
    path: 'sl',
    // pathMatch: 'full',
    loadChildren: () => import('./short-urls/short-urls.module').then((mod) => mod.ShortUrlsModule)
  },
  {
    path: '**',
    redirectTo: '',
  },
]; 

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabled',scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
