import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {
  companyDetails : any 
  officesDetails : any 
  featuresEnabled : any = []
  // officesListMeeting : any 
  storeVisitDataTemp  : any = null
  constructor(
    private router: Router
  ) { }
  featureExists(data,featureName,type){
    data.forEach(element => {
     if(type == 'meeting-room-and-desk'){
      if(element.isActive && this.checkOfficeAllFloorsIsActive(element.floorDetails)&& !element.isBulding){
        if(element.approvals && element.approvals.length > 0 && element.approvals.indexOf(featureName) !== -1 ){
          if(this.featuresEnabled && this.featuresEnabled.length > 0 && this.featuresEnabled.indexOf(featureName) !== -1){
          }else{
            this.featuresEnabled.push(featureName)
          }
        }
      }
     }else if(type == 'visitor-checkin'){
      if(element.isActive && element.isBulding){
        if(element.approvals && element.approvals.length > 0 && element.approvals.indexOf(featureName) !== -1 ){
          if(this.featuresEnabled && this.featuresEnabled.length > 0 && this.featuresEnabled.indexOf(featureName) !== -1){
          }else{
            this.featuresEnabled.push(featureName)
          }
        }
      }
     }
    });
  }
  generatePassFromCheckin(data){
    this.storeVisitDataTemp = data
  }
  checkOfficeAllFloorsIsActive(dat){
    let temp = false
    dat.forEach(element => {
      if(element.isActive){
        temp = true
        return temp
      }
    });
    return temp
  }
  checkIfCompanyIsDeactivated(){
    if(this.companyDetails && !this.companyDetails.isActive){
      if(window.location.href.indexOf('account-deactivated/')<0){
        this.router.navigate(['account-deactivated/'+this.companyDetails.id]);
        return true
      }
    }
  }
}
