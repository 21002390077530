import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import * as moment from 'moment';
import { ApiService } from '../app/@services/api.service'
import { SessionService } from '../app/@services/session.service'
import { Constants } from './global-data/constants';
import { commonFunctions } from './global-data/commonFunction';
import { SocketBookingsService } from '../app/@services/socket-bookings.service';
import jwt_decode from 'jwt-decode'
import { captureCompaignParameters } from './global-data/commonData';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Diginav';
  lastVisibilityChangeTime = Date.now();
  customLoader = false
  constructor(private router: Router,private api: ApiService,private session: SessionService,
    private commonFunctions: commonFunctions,
    private activatedRoute: ActivatedRoute,
    private socketBooking : SocketBookingsService
    ){
    let id = document.getElementById('firstLoad')
    id.style.display = 'none'
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(!(this.router.url == '/' || event.url.indexOf('/visitor-management') > -1 || event.url.indexOf('/meeting-room-and-desk-booking') > -1 )){
          this.commonFunctions.loadJquery()
        }
      }
    })
  }
  showNewUpdateModel : boolean  = false
  newVersionCode : any = null
  currentVersionCode : any = null
  appReloaded : any = null
  appVersion = '1.12.2';
  ngOnInit() {
    let location = window.location;
    // location.href.indexOf('/visitor-checkin')>=0 || 
    if(location.href.indexOf('/terms')>=0 || location.href.indexOf('/policy')>=0){
      this.checkAppVersion()
    }
    this.addWindowFocusListener();
    this.listenToInputEvents()
//     alert('hi')
//     {{qdfy}}/analytics/check/code/version -- GET

// {{qdfy}}/analytics/update/code/version -- POST
// body: {
//     "version": "1.0.1"
// }
    // if(localStorage.getItem('appReloaded')){
    //   this.appReloaded = localStorage.getItem('appReloaded')
    // }
    // this.getProjectVersion()
    window.onload = function () {
      setTimeout(() => {
        document.getElementById('google-tag-manager').innerHTML =
        '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-PS5FCM6" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j['async'] = true
        j['src'] = '//www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-PS5FCM6')
     }, 15000)
    }
    if(this.session?.getUser(true)?.userId && this.session?.getUser(true)?.email && this.session?.getUser(true)?.companyId){
      this.joinRoom()
    }
    this.checkCompaignTerms()
    // this.joinRoom()
  }
  joinRoom(){
    let joinRoomData = {
      userId : this.session.getUser(true).userId,
      email : this.session.getUser(true).email,
      companyId : this.session.getUser(true).companyId,
    }
    if(joinRoomData.userId && joinRoomData.email && joinRoomData.companyId){
      console.log(joinRoomData)
      this.socketBooking.joinRoom(joinRoomData)
    }
    
  }
  checkAppVersion(){
    let version = this.activatedRoute.snapshot.queryParamMap.get('v');
    this.api.getVersion('analytics/check/code/version',{}).subscribe((res:any)=>{
      if(res.error){
        console.log('Version Get API Error');
      }else{
        if(!version ||  version!= res['version']){
           this.activatedRoute.queryParams.subscribe(query => {
            query = {...query}
            query.v = res['version'];
             console.log('query===',this.activatedRoute,query)
            this.router.navigate([window.location.pathname],{ queryParams: query })
           })
            
        }
      }
      
    })
    
  }
  addWindowFocusListener(){
    window.addEventListener("visibilitychange", ( event)=> {
        if (document.visibilityState === "visible") {
            let currentTime = Date.now();
            let elapsedTime = currentTime - this.lastVisibilityChangeTime;
            if (elapsedTime >= 180000) { // 5 minutes in milliseconds
                console.log("System resumed after 5 minutes of hibernate mode.");
                if(window.location.href.includes('employee-access-view')){
                  this.customLoader = true;
                  // window.location.reload()
                  if(this.session?.getUser(true)?.userId && this.session?.getUser(true)?.email && this.session?.getUser(true)?.companyId){
                    this.joinRoom()
                  }
                  setTimeout(() => {
                    this.customLoader = false;
                  },4000)
                }
            }
        }
        this.lastVisibilityChangeTime = Date.now();
    }, false);

    window.addEventListener("online", (event) => {
      if(window.location.href.includes('employee-access-view')){
        // window.location.reload()
        this.customLoader = true;
        if(this.session?.getUser(true)?.userId && this.session?.getUser(true)?.email && this.session?.getUser(true)?.companyId){
          this.joinRoom()
        }
        setTimeout(() => {
          this.customLoader = false;
        },4000)
      }
    });

  }
  // getProjectVersion(){
  //   // let data = {
  //   //   "version": "1.1.5"
  //   // }
  //   // this.api.post('analytics/update/code/version',data).subscribe((res)=>{

  //   // })
  //   caches.keys().then(async function(names) {
  //     await Promise.all(names.map(name => caches.delete(name)));
  //   });
  //   if(localStorage.getItem('appReloaded') && JSON.parse(localStorage.getItem('appReloaded'))){
  //     this.appReloaded = JSON.parse(localStorage.getItem('appReloaded'))
  //   }
  //   if(!this.appReloaded){
  //     this.api.getVersion('analytics/check/code/version',{}).subscribe((res)=>{
  //       if(Constants.version != res['version']){
  //         if(caches){
  //           caches.keys().then(async function(names) {
  //             await Promise.all(names.map(name => caches.delete(name)));
  //           });
  //           this.appReloaded = {
  //             appReloaded : true,
  //             expireTime : moment(moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),'YYYY-MM-DDTHH:mm:ss.SSS[Z]').add(1,'hour').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  //           }
  //           localStorage.setItem('appReloaded',JSON.stringify(this.appReloaded))
  //           setTimeout(()=>{
  //             window.location.reload()
  //           },1000)
  //         }
  //       }
  //     })
  //   }else{
  //     console.log(moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
  //       if(moment(moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),'YYYY-MM-DDTHH:mm:ss.SSS[Z]').isSameOrAfter(moment(this.appReloaded.expireTime,'YYYY-MM-DDTHH:mm:ss.SSS[Z]'))){
  //         localStorage.removeItem('appReloaded')
  //         window.location.reload()
  //       }
  //   }
  // }
  private listenToInputEvents(): void {
    document.addEventListener('input', (event) => {
        
      const target:any = event.target ;
      if (target.tagName === 'INPUT' && target.value && (typeof(target.value)=='string' || typeof(target.value)=='number')) {
        if(event['inputType'] == 'insertText'){
          if(target.value.indexOf('  ')>=0){
            target.onblur = () => {
                target.value = target.value.trim()
                target.dispatchEvent(new Event('input'));// Manually trigger change detection
            }
          }
            target.value = target.value.replace('  ',' ')
        }else{
            target.value = target.value.trim();
              target.onblur = () => {
                  target.value = target.value.trim()
                  target.dispatchEvent(new Event('input')); // Manually trigger change detection
              }
        }
        
        if(typeof(target.value)=='string'){
            
        }
        if(typeof(target.value)=='number'){
            target.value = +(''+target.value).trim()
        }
        target.dispatchEvent(new Event('input'));
        
      }
    });
  }
  checkCompaignTerms(){

    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });

    if(captureCompaignParameters && captureCompaignParameters?.length){

      for(let item of captureCompaignParameters){

        console.log('queryParams[item.urlKey]---------',queryParams[item.urlKey]);

        if(queryParams[item.urlKey]){

          // localStorage.setItem(item.urlKey, queryParams[item.urlKey]);
          this.commonFunctions.createCookie(item.urlKey, queryParams[item.urlKey], 30)
        
        }

      }

    }

  

    

  }
} 
