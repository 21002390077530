import { Compiler, Component, Inject, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styleUrls: ['./shared-modal.component.scss']
})
export class SharedModalComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  public componentPlaceholder: ViewContainerRef;
  constructor(
    private dialogRef: MatDialogRef<SharedModalComponent>,
    private compiler: Compiler,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { }

  ngOnInit(): void {
    console.log('data------3--------',this.data);
    if(this.data.modulePath){
      this.loadModalComponent();
    }
  }
  closeModal(response){
    this.dialogRef.close(response)
  }
  loadModalComponent(){
    this.compiler.compileModuleAsync(this.data.module).then(moduleFactory => {
      const moduleRef:any = moduleFactory.create(this.injector);
      const componentFactory = moduleRef.instance.resolveComponent();
      const { instance }:any = this.container.createComponent(componentFactory);
      instance['allData'] = this.data;
      instance['dialogRef'] = this.dialogRef;
      setTimeout(() => {
        if(instance && instance.ngOnChanges){
          instance.ngOnChanges();
        }
      },300)
      setTimeout(() => {
        if(this.data.setupFunction){
          instance[this.data.setupFunction].apply()
        }
      },1000)
    });
}

}
